import React from 'react'
import About from './about'
import Education from './education'
import Experiences from './experiences'
import Projects from './projects'

export default class Resume extends React.Component {
  render() {
    const {projects} = this.props;

    return (
      <section className="ftco-section about-section">
        <div className="container">
          <About greeting={this.props.about?.greeting} descriptions={this.props.about?.descriptions}
            links={this.props.links} email={this.props.email} />
          <Projects projects={projects}/>
          <Experiences jobs={this.props.jobs} />
          <Education education={this.props.education} />
        </div>
      </section>
    )
  }
}

