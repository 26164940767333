import React from 'react'

export default class Footer extends React.Component {
  getLinks() {
    let children = [];
    if (this.props.links)
      for (let index = 0; index < this.props.links.length; index++) {
        children.push(<li key={index} className="ftco-animate">
          <a target="_blank" rel="noopener noreferrer" href={this.props.links[index].url}><span className={"icon-" + this.props.links[index].type} /></a>
        </li>);
      }
    return children;
  }

  render() {
    return (
      <footer className="ftco-footer ftco-bg-dark ftco-section">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-5 text-center">
              <div className="ftco-footer-widget mb-5">
                <ul className="ftco-footer-social list-unstyled">
                  {this.getLinks()}
                </ul>
              </div>
              <div className="ftco-footer-widget">
                <h2 className="mb-3">Contact Me</h2>
                <p className="h3 email">
                  <a href={"mailto:" + this.props.email}>{this.props.email}</a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <p>
                Copyright © All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true" style={{ color: 'red' }} /> by <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

