import React from 'react'

export default class Education extends React.Component {
  getEducation() {
    let children = [];
    
    if (this.props.education)
      for (let index = 0; index < this.props.education.length; index++) {
        children.push(<div key={index} className="exp-wrap py-4">
          <div className="desc">
            <h4>{this.props.education[index].desc} <span> - {this.props.education[index].school}</span> </h4>
            <p className="location">{this.props.education[index].location}</p>
          </div>
          <div className="year">
            <p>{this.props.education[index].year}</p>
          </div>
        </div>);
      }
    return children;
  }

  render() {
    return (
      <div className="exp mt-5 ftco-animate">
        <h2 className="mb-4">Education</h2>
        {this.getEducation()}
      </div>
    )
  }
}

