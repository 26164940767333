import React from 'react'

export default class ProgressBar extends React.Component {
    render() {
        return (
            <div className="KW_progressContainer">
                <div className="KW_progressBar">
                </div>
            </div>
        );
    }
}