import React from 'react'

export default class Slider extends React.Component {
  getItems() {
    let children = [];

    if (this.props.items)
      for (let index = 0; index < this.props.items.length; index++) {
        children.push(<div key={index} className="slider-item js-fullheight">
          <div className="overlay" />
          <div className="container">
            <div className="row slider-text align-items-center text-center justify-content-center" data-scrollax-parent="true">
              <div className="col-md-12 ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                <p><button type="button" className="scroll">{this.props.items[index].line1}</button></p>
                <h1 className="mb-3" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">{this.props.items[index].line2}</h1>
              </div>
            </div>
          </div>
        </div>);
      }
    return children;
  }

  render() {
    return (
      <section className="home-slider owl-carousel js-fullheight">
        {this.getItems()}
      </section>
    )
  }
}

