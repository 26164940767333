import React from 'react'

export default class Experiences extends React.Component {
  getExperiences() {
    let children = [];
    
    if (this.props.jobs)
      for (let index = 0; index < this.props.jobs.length; index++) {
        children.push(<div key={index} className="exp-wrap py-4">
          <div className="desc">
            <h4>{this.props.jobs[index].desc} <span> - {this.props.jobs[index].company}</span> </h4>
            <p className="location">{this.props.jobs[index].location}</p>
          </div>
          <div className="year">
            <p>{this.props.jobs[index].year}</p>
          </div>
        </div>);
      }
    return children;
  }

  render() {
    return (
      <div className="exp mt-5 ftco-animate">
        <h2 className="mb-4">Experience</h2>
        {this.getExperiences()}
      </div>
    )
  }
}

