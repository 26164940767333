import React, { Component } from 'react';
import './App.css';
import Header from './components/header'
import ProgressBar from './components/progressBar'
import Footer from './components/footer';
import Loader from './components/loader';
import Resume from './components/resume';
import Slider from './components/slider';

class App extends Component {
  state = {
  };

  constructor() {
    super();

    fetch('/data/data.json')
      .then((response) => response.json())
      .then((data) => {
        this.setState(data);
      });
  }

  componentDidUpdate() {
    window.doSomething();
  }

  render() {
    if (!this.state.fullname)
      return (<div></div>);

    return (
      <div>
        <ProgressBar />
        <div className="page">
          <div id="colorlib-page">
            <Header fullname={this.state.fullname} />
            <Slider items={this.state.sliderItems} />
            <Resume about={this.state.about} links={this.state.links} email={this.state.email} jobs={this.state.jobs} education={this.state.education} projects={this.state.projects} />
            <Footer links={this.state.links} email={this.state.email} />
            <Loader />
          </div>
        </div>
      </div>
    )
  }
}

export default App;
