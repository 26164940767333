import React from 'react'

export default class Projects extends React.Component {
    renderProjects() {
        return this.props.projects.map((project, index) => (
            <div key={index} className="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href={project.demoUrl} target="_blank" rel="noopener noreferrer"
                    className={`image ${index % 2 === 1 ? "order-2" : ""} d-flex justify-content-center align-items-center`}
                    style={{ backgroundImage: `url("images/${project.image}")` }} data-scrollax=" properties: { translateY: '-30%'}">
                    <div className="icon d-flex text-center justify-content-center align-items-center">
                        <span className="icon-search" />
                    </div>
                </a>
                <div className={`text ${index % 2 === 1 ? "order-1" : ""}`}>
                    <h4 className="subheading">{project.technologies}</h4>
                    <h2 className="heading">{project.name}</h2>
                    <p>{project.description}</p>
                    <p><a href={project.sourceCodeUrl} target="_blank" rel="noopener noreferrer">View Source Code</a></p>
                </div>
            </div>
        ));
    }
    render() {
        return this.props.projects && this.props.projects.length ? (
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-5">
                        <div className="col-md-7 text-center heading-section ftco-animate">
                            <span>Side Projects</span>
                            <h2>Checkout a few of my works</h2>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        {this.renderProjects()}
                    </div>
                </div>
            </section>
        ) : "";
    }
}
