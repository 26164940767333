import React from 'react'

export default class Header extends React.Component {
    render() {
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="colorlib-navbar-brand">
                                <a className="colorlib-logo" href="/">
                                    <span className="logo-img" style={{ backgroundImage: 'url(images/person_1.jpg)' }} />
                                    {this.props.fullname}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}