import React from 'react'

export default class About extends React.Component {
  getDescription() {
    let children = [];
    if (this.props.descriptions)
      for (let index = 0; index < this.props.descriptions.length; index++) {
        children.push(<p key={index}>{this.props.descriptions[index]}</p>);
      }
    return children;
  }

  getLinks() {
    let children = [];
    if (this.props.links)
      for (let index = 0; index < this.props.links.length; index++) {
        children.push(<li key={index}>
          <a href={this.props.links[index].url} target="_blank" rel="noopener noreferrer" >
            <span className={"icon-" + this.props.links[index].type} />
          </a>
        </li>);
      }
    return children;
  }

  render() {
    return (
      <section className="ftco-section about-section">
        <div className="container">
          <div className="row d-flex" data-scrollax-parent="true">
            <div className="col-md-4 author-img" style={{ backgroundImage: 'url(images/author-1.jpg)' }} data-scrollax=" properties: { translateY: '-70%'}" />
            <div className="col-md-2" />
            <div className="col-md-6 wrap ftco-animate">
              <div className="about-desc">
                <h1 className="bold-text">About</h1>
                <div className="p-5">
                  <h2 className="mb-5">{this.props.greeting}</h2>
                  {this.getDescription()}
                  <ul className="ftco-footer-social list-unstyled mt-4">
                    {this.getLinks()}
                  </ul>
                  <h5>Contact me here!</h5>
                  <p>Email: <a href={"mailto:" + this.props.email}>{this.props.email}</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

